<template>
  <h1>Banner to Article</h1>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'

export default {
  name: 'banner-to-article',
  data() {
    return {}
  },
  methods: {
    ...mapActions({
      setSelectedArticleKey: 'user/setSelectedArticleKey',
    }),
  },
  async created() {
    let article_key = this.$route.path.split('/')[3]
    if (!!article_key) {
      await this.setSelectedArticleKey(article_key)
      this.$router.push({ name: `announcement-detail` })
    } else {
      this.$router.push('/').catch((e) => {})
    }
  },
}
</script>
